<template>
    <div>
        Home
    </div>
</template>

<script setup>

</script>

<style>
</style>